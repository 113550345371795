// Google Recaptcha v3
grecaptcha.ready(function () {
	$('form.mod-recaptcha').each(function () {
		var object = $(this);
		// TODO: replace key with project one - this one is a Codename framework key
		grecaptcha.execute('6Le4I3kkAAAAAD2-eR9Q7i2AEgkDcZAlzXqruw1Q', { action: 'homepage' }).then(function (token) {
			$('<input>').attr({
				type: 'hidden',
				name: 'g-recaptcha-response',
				value: token
			}).appendTo(object)
		});
	});
});

// Show/hide cookie policy
$("body").on("click", ".section-cookies .btn-accept", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

$(".section-cookies .btn-decline").on("click", (ev) => {
	ev.preventDefault();

	$(".section-cookies").slideToggle();
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 10000);

		}
		else {
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});


// Google Maps
// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['SSAIB', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[
				{
					"featureType": "all",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"weight": "2.00"
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#9c9c9c"
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#f2f2f2"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#eeeeee"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#7b7b7b"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"color": "#46bcec"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#c8d7d4"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#070707"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				}
			],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 55.03793543622019, -1.4724411281227026, 14, true);
	}
}


// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

$(window).on('load', function () {
	setTimeout(function () {
		$('.footer-main').removeClass('mod-hide');
	}, 500);

	// $('.tweets').tweetie();
});

// Navigation functions

// $('.menu-parent').on('click', function(e) {
// 	$('body').css('overflowY', 'hidden');
// 	$('section.section-nav').removeClass('mod-hide');
// 	$('.sub-menu').addClass('mod-hide');
// 	$('.' + e['target']['classList'][1]).removeClass('mod-hide');

// 	$('.section-nav .nav-large .nav-list .nav-item.' + e['target']['classList'][1]).on('click', function(ev) {
// 		$('body').css('overflowY', 'unset');
// 		$('section.section-nav').addClass('mod-hide');
// 	})
// })

// Open About Menu
$('.about-ssaib.menu-parent').on('click', function (e) {

	if ($('.about-ssaib.menu-parent').hasClass('mod-active')) {
		$('body').css('overflowY', 'unset');
		$('section.section-nav').addClass('mod-hide');
		$('.section-nav .nav-large .sub-container .nav-list .nav-item.menu-parent').removeClass('mod-active')
	} else {
		$('body').css('overflowY', 'hidden');
		$('.section-nav .nav-large .sub-container .nav-list .nav-item.about-ssaib.menu-parent').addClass('mod-active')
		$('.section-nav .nav-large .sub-container .nav-list .nav-item.advice-and-services.menu-parent').removeClass('mod-active')
		$('section.section-nav').removeClass('mod-hide');
		$('.sub-menu').addClass('mod-hide');
		$('.' + e['target']['classList'][1]).removeClass('mod-hide');
	}

})

// Open Advice and Services Menu
$('.advice-and-services.menu-parent').on('click', function (e) {

	if ($('.advice-and-services.menu-parent').hasClass('mod-active')) {
		$('body').css('overflowY', 'unset');
		$('section.section-nav').addClass('mod-hide');
		$('.section-nav .nav-large .sub-container .nav-list .nav-item.menu-parent').removeClass('mod-active')
	} else {
		$('body').css('overflowY', 'hidden');
		$('.section-nav .nav-large .sub-container .nav-list .nav-item.advice-and-services.menu-parent').addClass('mod-active')
		$('.section-nav .nav-large .sub-container .nav-list .nav-item.about-ssaib.menu-parent').removeClass('mod-active')
		$('section.section-nav').removeClass('mod-hide');
		$('.sub-menu').addClass('mod-hide');
		$('.' + e['target']['classList'][1]).removeClass('mod-hide');
	}
})



// Close Advice and Services Menu (nav-item advice-and-services menu-parent mod-active)
$('.menu-parent.mod-active').on('click', function () {
	$('body').css('overflowY', 'unset');
	$('section.section-nav').addClass('mod-hide');
	$('.section-nav .nav-large .sub-container .nav-list .nav-item.advice-and-services.menu-parent').removeClass('mod-active')
})

$('.mob-hamburger').on('click', function () {
	$('body').css('overflowY', 'hidden');
	$('section.section-nav').removeClass('mod-hide');
	$('.sub-menu').addClass('mod-hide');
})

$('.icon-cross').on('click', function () {
	$('section.section-nav').addClass('mod-hide');
	$('body').css('overflowY', 'unset');
})

// Search Bar open and close
$('.icon-search-link').on('click', function (e) {
	e.preventDefault();
	$('.nav-list').addClass('mod-hide');
	$('.nav-buttons a.btn').addClass('mod-hide');
	$('.nav-buttons').css('marginLeft', '0')
	$('.sub-search-input-wrapper').removeClass('mod-hide');
});

$('.sub-search-input-wrapper .icon-cross').on('click', function (e) {
	e.preventDefault();
	$('.nav-list').removeClass('mod-hide');
	$('.nav-buttons a.btn').removeClass('mod-hide');
	$('.nav-buttons').css('marginLeft', '50px')
	$('.sub-search-input-wrapper').addClass('mod-hide');
})

// $('.section-nav .nav-large .sub-container .icon-search').on('click', function (e) {
// 	e.preventDefault();
// 	$('.section-search-mobile').removeClass('mod-hide');
// 	$('body').css('overflowY', 'hidden');
// })

$('.section-search-mobile .icon-cross').on('click', function (e) {
	e.preventDefault();
	$('.section-search-mobile').addClass('mod-hide');
	$('.section.section-nav').removeClass('mod-hide');
})

$('.item-career-opportunity').hover(function (e) {
	$(this).children('.career-opportunity-details').toggleClass('mod-hide');
	$(this).children('.career-link-text').toggleClass('mod-hide');
});

$('.read-more-button').on('click', function (e) {
	if ($(this)[0].innerText === 'Read more') {
		$(this).parent().css('height', '100%')
		$(this).text('Read less')
	} else {
		$(this).parent().css('height', '386px')
		$(this).text('Read more')
	}

	$(this).prev().toggleClass('mod-show-more')
});

$('.item-more-on').on('click', function (e) {
	$(this).children('.sub-more-on-wrapper').children('.sub-more-on-answer-block').toggleClass('mod-show');

	$(this).children('.sub-more-on-wrapper').toggleClass('active');

	setTimeout(function () {
		var getMeTo = e.target;
		getMeTo.scrollIntoView({ behavior: 'smooth' }, true);
	}, 300)
});

$('.view-schemes-btn').on('click', function () {
	setTimeout(function () {
		var getMeTo = document.getElementById('schemes');
		getMeTo.scrollIntoView({ behavior: 'smooth' }, true);
	}, 300)
})

$('.view-vacancies-btn').on('click', function () {
	setTimeout(function () {
		var getMeTo = document.getElementById('vacancies');
		getMeTo.scrollIntoView({ behavior: 'smooth' }, true);
	}, 300)
})

// News and Events filtering
$('.section-blog-list .wrap-list .sub-nav .filter-select').on('change', function (e) {
	var select_value = $(this).find(":selected").val()

	if (select_value !== '') {
		$('.section-blog-list .wrap-list .list-posts .item-post').removeClass('mod-show')
		$('.section-blog-list .wrap-list .list-posts .mod-' + select_value).addClass('mod-show')
	} else {
		$('.section-blog-list .wrap-list .list-posts .item-post').removeClass('mod-show');
		$('.section-blog-list .wrap-list .list-posts .item-post').addClass('mod-show');
	}
})

$('.item-info-page--privacy').on('click', function () {
	$(this).toggleClass('mod-active');
	$('.list-privacy-pages').toggleClass('mod-active');
});

$('.search-button').on('click', function (ev) {
	ev.preventDefault();

	$.ajax({
		url: 'search',
		type: "GET",
		data: $('.search-wrap .searchterm').val()
	}).done(function () {
		var loc = '/search?searchterm=' + $('.search-wrap .searchterm').val();
		window.location.href = loc
	});
});

if ($('body#search').length) {
	// Change still of checkbox
	$('body#search .aside-filters input[type="checkbox"]').on('change', function () {

		$(this).parents('.sub-checkbox-wrap').removeClass('mod-checked');

		if ($(this).is(":checked")) {
			$(this).parents('label').addClass('mod-checked');
			$(this).parents('.sub-checkbox-wrap').addClass('mod-checked');
		}
	});

	$('body#search .list-categories .sub-checkbox-wrap input[type="checkbox"]').on('change', function () {
		$('body#search .loading_overlay').fadeTo(300, 0.5);

		var loc = window.location.pathname + '?searchterm=' + $('.section-search-bar .searchterm').val();

		$('.list-categories .sub-checkbox-wrap input[type="checkbox"]:checked').each(function (i) {
			var separator = loc.indexOf('?') !== -1 ? "&" : "?";

			if ($(this).prop('checked')) {
				loc += separator + $(this).attr("name") + "=" + $(this).attr("value");
			}
		});

		history.pushState(null, '', loc);

		$.ajax({
			url: 'api/filters',
			type: "GET",
			data: $('body#search .list-categories .sub-checkbox-wrap input[type="checkbox"]:checked').serialize() + '&searchterm=' + $('.section-search-bar .searchterm').val()
		}).done(function (response) {
			if (!response) {
				$('body#search .section-results');
			} else {
				$('body#search .section-results').fadeIn().html(response);
			}
		});

		$('body#search .loading_overlay').fadeOut(300);
	});

	$('body#search .aside-filters .sub-reset').on('click', function (ev) {
		ev.preventDefault();

		$('body#search .loading_overlay').fadeTo(300, 0.5);

		$('body#search .list-categories .sub-checkbox-wrap input[type="checkbox"]:checked').prop('checked', false);
		$('body#search .list-categories li .sub-checkbox-wrap').removeClass('mod-checked');

		var loc = location.href.split("&category")[0];

		history.pushState(null, '', loc);

		$.ajax({
			url: 'api/filters',
			type: "GET",
			data: $('body#search .list-categories .sub-checkbox-wrap input[type="checkbox"]:checked').serialize() + '&searchterm=' + $('.section-search-bar .searchterm').val()
		}).done(function (response) {
			$('body#search .section-results').fadeIn().html(response);
		});

		//hide loading overlay
		$('body#search .loading_overlay').fadeOut(300);
	});

	$('body#search .sub-main-filter-btn').on('click', function (ev) {
		ev.preventDefault();

		$('body#search .aside-filters').addClass('mod-active');
		$('body#search .sub-main-filter-btn').addClass('mod-hide');
	});

	$('body#search .aside-filters .sub-filter-mob').on('click', function (ev) {
		ev.preventDefault();

		$('body#search .aside-filters').removeClass('mod-active');
		$('body#search .sub-main-filter-btn').removeClass('mod-hide');
	});
}
